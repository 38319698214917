<template>
  <v-container
    id="matriculados-caplp"
    fluid
    grid-list-xl
  >
    <!-- <v-layout>
      <v-flex
        xs12
        class="alineaizquierda"
      >
        <h3>Reportes de Matriculados</h3>
      </v-flex>
    </v-layout> -->
    <v-layout>
      <v-flex
        xs12
        class="alineaizquierda"
      >
        <v-select
          v-model="idreportesel"
          :items="listareportes"
          label="Reporte"
          outlined
          item-text="nombre"
          item-value="idreporte"
          dense
        />
        <v-select
          v-model="formatosel"
          :items="listaformatos"
          label="Formato"
          outlined
          item-text="formato"
          item-value="formato"
          dense
        />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex
        xs12
        class="alineaizquierda"
      >
        <v-btn
          color="green darken-1"
          text
          @click="ExportaReporte"
        >
          Exportar
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model = "loading"
      fullscreen
      full-width
    >
      <v-container
        fluid
        class = "full-height"
        style = "background-color: rgba(255, 255, 255, 0.5);"
      >
        <v-layout
          align="center"
          justify="center"
          style="height:100vh"
          no-gutters
          dense
        >
          <v-flex
            class = "elementocentrado"
          >
            <v-progress-circular
              indeterminate
              color = "primary"
            />
            <!-- </v-progress-circular> -->
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-dialog
      v-model="dialogmensaje"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ mensajetitulo }}
        </v-card-title>
        <v-card-text>
          {{ mensajecuerpo }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="green darken-1"
            text
            @click="dialogmensaje = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from '@/axios'
export default {
  name: 'ReportesMatriculados',
  data () {
    return {
      loading: false,
      dialogmensaje: false,
      mensajetitulo: '',
      mensajecuerpo: '',
      listaformatos: [ { formato: 'PDF' }, { formato: 'EXCEL' } ],
      formatosel: '',
      idreportesel: '',
      listareportes: []
    }
  },
  mounted () {
    this.loading = true
    this.CompletaCombos()
  },
  methods: {
    CompletaCombos () {
      axios
        .get('reportes')
        .then((result) => {
          this.listareportes = result.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.MostrarMensaje('Error', error)
        })
    },
    ExportaReporte () {
      if (this.idreportesel !== null && this.idreportesel !== '') {
        if (this.formatosel !== null && this.formatosel !== '') {
          this.loading = true
          axios
            .get('matriculado', { params: { idreporte: this.idreportesel, formato: this.formatosel } })
            .then((result) => {
              this.loading = false
              if (result.data.toString().startsWith('Error') === false) {
                window.open(result.data, '_blank')
              } else {
                this.MostrarMensaje('Error', result.data)
              }
          })
            .catch((error) => {
              this.loading = false
              this.MostrarMensaje('Error', error)
          })
        } else {
          this.MostrarMensaje('Error', 'Debe seleccionar el formato!!!')
        }
      } else {
        this.MostrarMensaje('Error', 'Debe seleccionar un reporte!!!')
      }
    },
    MostrarMensaje (titulo, cuerpo) {
        this.mensajetitulo = titulo
        this.mensajecuerpo = cuerpo
        this.dialogmensaje = true
    }
  }
}
</script>
<style>
  .textocentrado{
    text-align: center;
  }
  .elementocentrado{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding: 2px !important;
  }
  @media only screen and (min-width: 960px) {
    .altojusto{
      height: 45px !important;
    }
  }
  .alineaizquierda{
    text-align: left;
  }
  .alineacentro{
    text-align: center;
  }
  .alineaderecha{
    text-align: right;
  }
</style>
